import "../../core/src/components/tags/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/tags/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB95yvclSpBt14lbPZmtP2OShWqjDNJ3HVsy3YgabX/XtkBkkBCl4c+AGLuc87MJHc/4zensib6kVJHMZTaNa/OVLBGf2YIMSWUIWhLzRxjmmVcAtaCMiiUSMHgoF+sZu+zu32gOPil3GpBG4IyAfVqhsIvTrkB5riSxAeuSuk1O566gqA4ij77vznVBMVLXfeDLkPQoFpGQ9X9eL5g+6i73DvjJf77LGkXLAnBNE1TLnOCIm+qleVtzQYEdXwLXuqUJohRweb4/u5B1+h2D5KhKa+xVlqDwVSyQhlcAM8Lt0BfEI4X3ltA5ghqPXvlhHjTYYLVAt2i+FHXIY7agsmE2hFU8DQF2e/l4QIwySQwx97jSNd7HhDaUPaWG1XJFI9NxHaJudSVw51dKK/kct87QckZpcNCN8r4gfJ9V5ZckVnQBkwvM9aGl9Q0g6l8DDlCv7nxeMXnzQa2S2pyLgnCnaitjCCpZKBeVU5wCZ0gU9Jhy38DQfEBWS/KaMlFQxCXBRjuVt0+9STnDTpDpdXUgHSDFgjpbd7kdm6X2EHt+ls6QOLp2v0MA/PcttVthpccPi1udUd1FLXCbjxp5dQ4+08jK9cP1oLceT6P1/+rso5nDWZKOpCOIKspA7wBtwMIfVDBc4m5g9ISxEA6MOctndA5QDbIa7s42uz2JT5E0WqCjUCFBaZkSk0zci47INrGLia35cD3JXgcQMZ1dwWmrlYf2OWBpY7gsbJe/tt27jNHAxaS83E6nQD6sQk2ancV7Yd098kpLAixyljfnVb8EOAEkWSIH20fp5lilYW090D9B2KFZ/PimLTdH1ctmeLtpIIbX8LNesT5ihO0aQdOU8ZdE5h7nxFu58ecJNT/9VjEcoEmfONB3LZW66iDV6VBri8Ys5FT3mnT4TkJV/wjex1W66odPp2YMRLSi2RlQRuwzpQp97AL6uD7PAlP+M42v/Zsj6769EHOWwra1w7LjBICUwMUbznstDJujb6hlG+HZTi6EYA+8dJb0DAsfwFxixfqUQoAAA==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_1ktofy3';
export var menuItemList = '_1ktofyg';
export var menuItemListScrollable = '_1ktofye';
export var menuItemListScrollbar = '_1ktofyf';
export var searchInput = '_1ktofy6';
export var spacer = '_1ktofyc';
export var tagEditIcon = '_1ktofyb';
export var tagSelectorEmpty = '_1ktofyd';
export var tagSelectorItem = '_1ktofya';
export var tagSelectorTagsScrollContainer = '_1ktofy9';
export var tagsEditorRoot = '_1ktofy1';
export var tagsEditorRootMobile = '_1ktofy2 _1ktofy1';
export var tagsEditorSelectedTags = '_1ktofy5';
export var tagsEditorTagsSelector = '_1ktofy7';
export var tagsEditorTagsSelectorHeader = '_1ktofy8';
export var tagsInlineEditor = '_1ktofy0';
export var tagsMenu = '_1ktofy4';