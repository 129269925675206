import "../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/bi-directional-link-panel.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWTW/bMAy951cQAwY0wxQ4btq17goM22W3/YBhB9qiHa2yZEhynGzIfx8sO47tqk13CkKRj4+PH8kqpdI2uwj+LgAawd02gXUUvX9YAJS4Z71ph+aKMcxzoYgRF06b7mnZOZpCKCYpdwlg7fTIaESxHVkr5FyoovcNwVrBifVuHyHeLMdhPdqb43KtHLPiD81ivD1FS8uHxXGx6kRYexG21OVY31b7h+eicGEriYcEckn+/XdtncgPLNPKkXIJZKQcmfYJpSgUE45KezYP6WKfLsXsqTC6VnxGMdWGk2GZltr4Wk7EotVNgNkAe+1hL9O0FWbEUnINkbrMduNhvW5NT+QmimYar3tmsi3gxDfedEZfyaxIR3vHKiNKNIeh1CHnzaQh8V2H0wtjkIvaJjCxJrCu9mC1FPyCmmfdWYhYsxWOvKOn6LUZt3ZcdBwquqcVEuzNQgBktbGtb6XFrB+3Xpt+yVLtnC5PMzv4fPI+L6azlGnFpwlP/K9DRXXGAf4udDQG36gDeDaIwTEDKLBKoJ8ULz7zA5qA0o3BKtD4zZRMstU7MrOdCvZ2FzOJBzJs5OeDf+zISDwsJzWu+ihDORlSGTEnnKQuUcfoJL/Sikax9+FNbD8ZF4YyJ3Q7VFrWpRo0OI1T31qnz7q83m306aYnLsdSyEPo+FlUtn+eTBqnHGvpxivlD05oqXaxl1AK9WRZKnX29NWHLF9e0/5AD99f6M94Hd6yrgEmQ8C3+VnB/xqVGe73NnSCBh+6NZsLOHikk/fnq5z5d2dQWdGNBEoJ0Sq2QGiJCcV07V5RS2RaTdQaXac4mgxJ9pOjw7ZWiZUl/vjOmZre/TpTyLUpEzDaoaOr+4hTsbyUejgkXpcvJXGBUBmhnIddjf9jjBaiWxeAYxvU/i6hUGRgJ6iptHFw5U8LfH6E201U7ZchsGGeouF35rg4/gMaM4Co1ggAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var collapsedIcon = 'bemswvc';
export var container = 'bemswv0';
export var divider = 'bemswv2';
export var dividerContainer = 'bemswv1';
export var link = 'bemswv8';
export var linkPreview = 'bemswva';
export var linkPreviewContainer = 'bemswv9';
export var linkPreviewRenderer = 'bemswvb';
export var linksContainer = 'bemswv6';
export var linksTitles = 'bemswv7';
export var showButton = 'bemswv5';
export var title = 'bemswv4';
export var titleLine = 'bemswv3';