import "../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/blocksuite/block-suite-editor/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61TzW7bMAy+5yl4GZoAVWBnaZoqK7D3GHZgLNrmJkuezMTuhrz7YDvO7DQdOmAnG5/I74eUlj/2aB83EfyaAfgjhdT6WjUaEsvlbgZguCotvmhILTUt0H6V4UCJsHcaEm8PhdvNTrNlzxV3XDlxlouGOIo+DG0a4n+iBCjRGHaZ2nsRX3RsZTPSWv1d61L3sau7CO+tT76Pj3WO1RzTlB0pZFWiI6tqNhnJF4OCqhIUer7LyFFAYZfdfV10nNcOn7ZTh++mTtlxlZP578QUgg/vZV3fHBRAzUbyPxMuMGTsNESAB/HjTYkvNTx0pK/VBjw5hMoHDUKNXC5AFnw93dpDZyb1TlTFP0nDEcNcqXPmDs/VerEbiurzPdhE0Ui9dbnudfc+GAoqoOFDpQe0D6MspXLBbKsw3KvpiDadq8TbNsHEURtHVZR4ZzC8qK5kMep8fLNzH9CZ1w3bruFIQThBq9By5jQUbIyl3XQ08TKmosUkoKtSHwrd/1oUmq/K5h5UXDZj9qfprofXeLXqb4dKOG3TOCEnGhJyQmHEg7d53n7VGZYatuUttQIbdYYmAyLD4kN/tLha7q3Cig2pc809rNbn5J/bFMiOAhyZ6tIHgXlHCp+eYbOOyqZ/J5NoE7V40xs/zU6/AfcNMnQ9BQAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineDocViewport = 'qbal761';
export var affineEdgelessDocViewport = 'qbal762';
export var docContainer = 'qbal763';
export var docEditorGap = 'qbal764';
export var docEditorRoot = 'qbal760';
export var docPropertiesTable = 'qbal76a';
export var docPropertiesTableContainer = 'qbal769';
export var pageReferenceIcon = 'qbal768';
export var titleDayTag = 'qbal766 qbal765';
export var titleTodayTag = 'qbal767 qbal765';